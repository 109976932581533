html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0;
  padding: 0;
  line-height: 1.5;
  min-height: 100vh;
  margin: 0;
  padding: 1rem;
  background: #f7f8fb;
  color: #001331;
  display: flex;
  justify-content: center;
  align-items: center;
}

main {
  max-width: 40rem;
}

h1 {
  user-select: none;
  cursor: default;
  position: relative;
  color: #001331;
  font-size: 2.5rem;
  font-weight: 200;
  line-height: 1.25;
  margin: 0;
  padding: 0;
}

@media (min-width: 40rem) {
  h1 {
    font-size: 3rem;
  }
}
@media (min-width: 60rem) {
  h1 {
    font-size: 3.5rem;
  }
}
p {
  user-select: none;
  cursor: default;
  position: relative;
  color: #8591a3;
  font-size: 1rem;
  font-weight: 200;
  margin: 0 0 1rem;
}

textarea,
input {
  display: block;
  margin: 0;
  padding: 0.25rem 0.5rem;
  width: 100%;
  background: #fff;
  border: 1px solid #a7aeba;
  border-radius: 4px;
}
textarea:focus,
input:focus {
  border-color: #00f;
  outline: 0;
}

textarea {
  min-width: 100%;
  max-width: 100%;
  font-size: 1rem;
}

input {
  font-size: 1.25rem;
}
input[type=file] {
  display: none;
}

button {
  display: inline-block;
  user-select: none;
  cursor: pointer;
  color: #0000ff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.15;
  margin: 1rem 0;
  padding: 0.375em 1em;
  background: none;
  border: 2px solid currentColor;
  border-radius: 4px;
  outline: none;
}
button:disabled {
  cursor: not-allowed;
  color: #dee2ec;
}
button + button {
  margin-left: 0.5rem;
}

pre {
  font-size: 0.75rem;
  text-align: left;
  overflow: hidden;
}

pre code {
  display: block;
  padding: 0.5rem 1rem;
  border: 1px solid #a7aeba;
  border-radius: 4px;
  overflow: auto;
}

table {
  position: relative;
  font-size: 0.875rem;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid #a7aeba;
  border-radius: 4px;
}
table tr {
  display: table-row;
  white-space: nowrap;
}
table th,
table td {
  display: table-cell;
  font-size: 0.875rem;
  text-align: left;
}
table th {
  padding: 1rem 1rem 0;
}
table td {
  padding: 0.5rem 1rem 1rem;
}
table input {
  min-width: max-content;
}